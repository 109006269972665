import React, { FunctionComponent } from 'react';
import sharedStyles from '../content-styles.module.scss';
import { ResponseInlineVideo } from '../responsive-video.component';

export const AICompetitiveEdge: FunctionComponent = () => {
    return (
        <div className={sharedStyles.container}>
            <div className={sharedStyles.description} style={{ minHeight: 80 }}>
                As data gathering increases, Telemetry Sports leverages AI technology to not only enhance data
                collection but also to genera te and analyze new data points. We transform trusted data into valuable
                insights, equipping your organization with the power of informed decision-making. Our AI-driven
                solutions empower you to efficiently process vast volumes of data, ensuring that every a ction is
                underpinned by actionable intelligence.
            </div>
            <div className={sharedStyles.videoContainer}>
                <ResponseInlineVideo
                    url="https://s3.amazonaws.com/scratch.telemetry.fm/ar/telemetry-website/videos/ai/TS_EdgeOver.mp4"
                    bordered
                />
            </div>
        </div>
    );
};

import React, { FunctionComponent } from 'react';
import sharedStyles from '../content-styles.module.scss';
import { ResponseInlineVideo } from '../responsive-video.component';
import { QuoteCarousel } from '../../quote-carousel/quote-carousel.component';

const QUOTES = [
    'AI is used to simulate and evaluate games and provide metrics, helping coaches and staff make better decisions.',
];

export const PlayerEvaluation: FunctionComponent = () => {
    return (
        <div className={sharedStyles.container}>
            <div className={sharedStyles.description} style={{ minHeight: 85 }}>
                At Telemetry Sports, our commitment to player evaluation is driven by cutting-edge proprietary metrics.
                Our unique player scores and evaluation tools enable us to do more than just analyze past performance –
                we can foresee how players will transition to the next level. By leveraging these advanced metrics, we
                empower your organization to make data-backed decisions on player development, evaluation, and scouting.
                Whether it&apos;s evaluating a college prospect&apos;s readiness for the NFL or identifying untapped
                potential, our metrics are your key to staying ahead of the competition
            </div>
            <div className={sharedStyles.videoContainer}>
                <ResponseInlineVideo
                    url="https://s3.amazonaws.com/scratch.telemetry.fm/ar/telemetry-website/videos/ai/TS_PlayerEval.mp4"
                    bordered
                />
            </div>
            <QuoteCarousel quotes={QUOTES} />
        </div>
    );
};

import React, { FunctionComponent } from 'react';
import sharedStyles from '../content-styles.module.scss';
import { ResponseInlineVideo } from '../responsive-video.component';

export const AIChangingTheGame: FunctionComponent = () => {
    return (
        <div className={sharedStyles.container}>
            <div className={sharedStyles.videoContainer} style={{ marginBottom: 30 }}>
                <ResponseInlineVideo
                    url="https://s3.amazonaws.com/scratch.telemetry.fm/ar/telemetry-website/videos/ai/TS_ChangingGame.mp4"
                    bordered
                />
            </div>
            <div className={sharedStyles.description} style={{ minHeight: 150, flexDirection: 'column' }}>
                At Telemetry Sports, we&apos;re harnessing the power of AI to revolutionize the world of sports data.
                Our suite of AI - driven products , including solutions tailored for Pro, College, Recruit, and
                Broadcast, is designed to redefine the way you engage with sports analytics. With Telemetry Sports AI,
                you can:
                <ul>
                    <li>Seamlessly integrate AI support into your existing processes, enhancing efficiency.</li>
                    <li>
                        Analyze vast volumes of data that surpass human processing capabilities, uncovering details and
                        patterns hidden to the naked eye.
                    </li>
                    <li>
                        Streamline and accelerate informed decision - making, providing a competitive boost to your
                        organization.
                    </li>
                    <li>
                        Elevate the fan broadcast experience, providing deeper engagement and insights for sports
                        enthusiasts.
                    </li>
                    <li>
                        Take proactive measures to prevent player injuries, safeguarding your team&apos;s performance
                        and well-being.
                    </li>
                </ul>
            </div>
        </div>
    );
};

import React, { FunctionComponent } from 'react';
import { ResponseInlineVideo } from '../responsive-video.component';

import sharedStyles from '../content-styles.module.scss';

export const AIInSports: FunctionComponent = () => {
    return (
        <div className={sharedStyles.container}>
            <ResponseInlineVideo
                url={'https://s3.amazonaws.com/scratch.telemetry.fm/ar/telemetry-website/videos/ai/TS_AIOverview.mp4'}
            />
        </div>
    );
};

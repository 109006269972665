import React, { FunctionComponent } from 'react';
import sharedStyles from '../content-styles.module.scss';
import { ResponseInlineVideo } from '../responsive-video.component';
import { QuoteCarousel } from '../../quote-carousel/quote-carousel.component';

const QUOTES = [
    'We are leveraging all of the information out there, taking it and distilling it into useful things by looking for patterns and data that isn’t obvious to the human eye.',
    'Average humans cannot look at and digest this much data. We are augmenting and optimizing their ability/multiplying and amplifying their capabilities.',
];

export const ComputerVision: FunctionComponent = () => {
    return (
        <div className={sharedStyles.container}>
            <div className={sharedStyles.description}>
                Computer vision and machine learning are different segments of AI that go hand-in-hand in building out
                models that allow organizations to be more efficient, learn about tendencies and predict outcomes of
                players, plays and games. For computer vision, specifically, we train models that can interpret and
                learn from game film to output valuable data such as performance metrics. While Computer Vision is not
                predictive, it can take in frames of video or single frame images to objectively analyze data.
            </div>
            <div className={sharedStyles.videoContainer}>
                <ResponseInlineVideo
                    url="https://s3.amazonaws.com/scratch.telemetry.fm/ar/telemetry-website/videos/ai/TS_ComputerVision.mp4"
                    bordered
                />
            </div>
            <QuoteCarousel quotes={QUOTES} />
        </div>
    );
};

import React, { FunctionComponent, useMemo, useState } from 'react';
import { Variants, motion } from 'framer-motion';

import webStyles from './ai-boxes.module.scss';
import mobileStyles from './ai-boxes-mobile.module.scss';
import useMobile from 'src/shared/hooks/use-mobile';
import { useElementSize } from '@mantine/hooks';
import * as Content from './content';

type AIBoxItem = {
    key: string;
    title: string;
    component: FunctionComponent;
};

const items: AIBoxItem[] = [
    { key: 'ai-sports', component: Content.AIInSports, title: 'AI in Sports' },
    { key: 'ai-ts', component: Content.AIAtTelemetry, title: 'AI with Telemetry Sports' },
    { key: 'war', component: Content.WinsAboveReplacement, title: 'Wins Above Replacement (WAR)' },
    { key: 'player-eval', component: Content.PlayerEvaluation, title: 'Player Evaluation / Proprietary Metrics' },
    { key: 'ai-game-changer', component: Content.AIChangingTheGame, title: 'AI & Changing the Game' },
    { key: 'competitive-edge', component: Content.AICompetitiveEdge, title: 'Our AI Competitive Edge' },
    { key: 'computer-vision', component: Content.ComputerVision, title: 'Computer Vision Explained' },
    { key: 'ml', component: Content.MachineLearning, title: 'Machine Learning and How it Works' },
];

const variants: Variants = {
    initial: { x: -40, opacity: 0 },
    animate: {
        x: 0,
        opacity: 1,
        transition: {
            delay: 0.3,
            type: 'spring',
            stiffness: 100,
            mass: 0.3,
        },
    },
    hover: { cursor: 'pointer' },
};

const activeItemVariants: Variants = {
    initial: {
        x: 0,
        transition: { duration: 0.3, type: 'tween', ease: 'easeIn' },
    },
    rest: {
        scale: 1,
        x: 0,
        transition: { duration: 0.3, type: 'tween', ease: 'easeIn' },
    },
    hover: {
        scale: 1,
        x: 0,
    },
};

const itemVariants: Variants = {
    rest: { scale: 1, x: 0, transition: { duration: 0.3, type: 'tween', ease: 'easeIn' } },
    hover: {
        cursor: 'pointer',
        color: '#616161',
        scale: 1.1,
        x: -15,
        transition: {
            duration: 0.4,
            type: 'tween',
            ease: 'easeOut',
        },
    },
};

const barVariants: Variants = {
    rest: { marginLeft: 5, height: 3, width: 20, backgroundColor: '#676767' },
    hover: { marginLeft: 5, height: 4, width: 30, backgroundColor: '#2e9ef2', transition: { duration: 0.4 } },
};

const activeBarVariants: Variants = {
    initial: { marginLeft: 5, height: 4, width: 30, backgroundColor: '#2e9ef2' },
    rest: { marginLeft: 5, height: 4, width: 30, backgroundColor: '#2e9ef2' },
    hover: { marginLeft: 5, height: 4, width: 30, backgroundColor: '#2e9ef2' },
};

export const AIBoxes: FunctionComponent = () => {
    const { ref } = useElementSize();
    const mobile = useMobile();
    const styles = mobile ? mobileStyles : webStyles;

    const [active, setActive] = useState<string>('ai-sports');

    return (
        <motion.div className={styles.container}>
            <motion.div className={styles.options}>
                <motion.div
                    className={styles.optionsContainer}
                    whileInView={'animate'}
                    initial="initial"
                    variants={variants}
                >
                    {items.map((item) => {
                        const isActive = item.key === active;
                        return (
                            <motion.div
                                key={item.key}
                                className={isActive ? styles.activeItem : styles.option}
                                initial={'initial'}
                                whileHover={'hover'}
                                animate={'rest'}
                                onClick={() => {
                                    setActive(item.key);
                                    document.getElementById(item.key)?.scrollIntoView({ behavior: 'smooth' });
                                }}
                            >
                                <motion.span
                                    key={`${item.key}-${isActive}-span`}
                                    variants={isActive ? activeItemVariants : itemVariants}
                                >
                                    {item.title}
                                </motion.span>
                                <motion.div
                                    key={`${item.key}-${isActive}-bar`}
                                    variants={isActive ? activeBarVariants : barVariants}
                                />
                            </motion.div>
                        );
                    })}
                </motion.div>
            </motion.div>
            <motion.div
                style={{ display: 'flex', flexDirection: 'column', flex: 1 }}
                initial="initial"
                whileInView={'animate'}
            >
                <motion.div
                    // initial="initial"
                    variants={variants}
                    style={{ height: 10, width: 80, backgroundColor: '#2e9ef2' }}
                />
                <div className={styles.content} ref={ref}>
                    {useMemo(() => {
                        return items.map((i) => {
                            return (
                                <div id={i.key} key={i.key} className={styles.contentChild}>
                                    <i.component />
                                </div>
                            );
                        });
                    }, [])}
                </div>
                <motion.div
                    // initial="initial"
                    variants={variants}
                    style={{ height: 10, width: 80, backgroundColor: '#2e9ef2' }}
                />
            </motion.div>
        </motion.div>
    );
};

import React, { FunctionComponent } from 'react';
import sharedStyles from '../content-styles.module.scss';

export const MachineLearning: FunctionComponent = () => {
    return (
        <div className={sharedStyles.container}>
            <div
                className={sharedStyles.description}
                style={{ flexDirection: 'column', padding: 60, fontSize: '1.2rem' }}
            >
                <p>
                    Machine learning, which is the other leg of our artificial intelligence, allows us to predictively
                    analyze tendencies and outcomes of players, plays and games.
                </p>
                <p>
                    Think of machine learning like training or preparing a football team. The coaches (machine learning
                    algorithms) look at large sample of past game footage and player metrics (data) and see patterns
                    they can use to predict future outcomes. Maybe when the quarterback throws short into certain
                    coverage, their success rate is substantially higher. The coach doesn&apos;t just memorize a single
                    game or single play, but rather learns what generally works.
                </p>
                <p>
                    In machine learning, the algorithm &quot;learns&quot; from a lot of data to make predictions or
                    decisions. For example, just like a coach might say,{' '}
                    <span style={{ fontWeight: 800 }}>
                        &quot;When the defense is in press coverage, we want to run play X&quot;
                    </span>
                    , a machine learning model could say,{' '}
                    <span style={{ fontWeight: 800 }}>
                        &quot;Based on all past data, here&apos;s what is likely to happen next.&quot;
                    </span>
                </p>

                <p>
                    Utilizing machine learning allows us to analyze millions of data points, spanning every player in
                    every play of every game to find trends and predict outcomes.
                </p>
            </div>
        </div>
    );
};

import React, { FunctionComponent } from 'react';
import { ResponseInlineVideo } from '../responsive-video.component';

import sharedStyles from '../content-styles.module.scss';
import { QuoteCarousel } from '../../quote-carousel/quote-carousel.component';

const QUOTES = [
    'We have gathered so much knowledge along the way that makes us experts in Artificial Intelligence in football.',
    'We use computers and software to perform tasks that a human would have to spend a lot more time on, making football staffs more efficient and providing them information they need to make better decisions.',
];

export const AIAtTelemetry: FunctionComponent = () => {
    return (
        <div className={sharedStyles.container}>
            <div className={sharedStyles.description} style={{ minHeight: 80 }}>
                At Telemetry Sports, we are at the forefront of Artificial Intelligence (AI) innovation, redefining the
                game of football through a data-driven approach. By employing state-of-the-art computer vision
                technology for data generation and advanced machine learning algorithms, we&apos;re revolutionizing data
                processes for our clients. With Telemetry Sports, you&apos;re not just getting data-backed insights;
                you&apos;re gaining a competitive edge to help you win in the margins.
            </div>
            <div className={sharedStyles.videoContainer}>
                <ResponseInlineVideo
                    url="https://s3.amazonaws.com/scratch.telemetry.fm/ar/telemetry-website/videos/ai/TS_ComputerVision.mp4"
                    bordered
                />
            </div>
            <QuoteCarousel quotes={QUOTES} />
        </div>
    );
};
